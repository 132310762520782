import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import cn from "classnames";
import { Container, Row, Col } from "@ui/wrapper";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
import Heading from "@ui/heading";
import Social, { SocialLink } from "@ui/social";
import { SocialType } from "@utils/types";
import {
  FooterWrap,
  FooterTop,
  FooterWidget,
  LogoWidget,
  TextWidget,
  FooterBottom,
} from "./style";

const Footer = () => {
  const contactoData = useStaticQuery(graphql`
    {
      site(siteMetadata: {}) {
        siteMetadata {
          socials {
            icon
            id
            link
            title
          }
          copyright
          contact {
            email
            phone
            address
          }
        }
      }
    }
  `);
  const { socials, copyright, contact } = contactoData.site.siteMetadata;
  return (
    <FooterWrap>
      <FooterTop>
        <Container>
          <Row>
            <Col lg={4} sm={6}>
              <FooterWidget mb={["31px", null, null, 0]}>
                <LogoWidget>
                  <StaticImage
                    src="../../../assets/images/logo/dark-logo-160x48.png"
                    alt="logo"
                    width={160}
                    height={48}
                  />
                </LogoWidget>
                <TextWidget>
                  {contact.address && <Text mb="10px">{contact.address}</Text>}
                  {contact.email && (
                    <Text mb="10px">
                      <Anchor
                        path={`mailto:${contact.email}`}
                        color="text"
                        $hover={{ layout: 2 }}
                      >
                        {contact.email}
                      </Anchor>
                    </Text>
                  )}
                  {contact.phone && (
                    <Text mb="10px">
                      <Anchor
                        path={`tel:${contact.phone}`}
                        fontWeight="800"
                        color="heading"
                        $hover={{ layout: 2 }}
                      >
                        {contact.phone}
                      </Anchor>
                    </Text>
                  )}
                </TextWidget>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget mb={["31px", null, null, 0]}>
                <Heading as="h6" mt="-3px" mb="20px">
                  <Anchor path="/" color="text" $hover={{ layout: 2 }}>
                    Servicios
                  </Anchor>
                </Heading>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget mb={["27px", null, 0]}>
                <Heading as="h6" mt="-3px" mb="20px">
                  <Anchor path="/" color="text" $hover={{ layout: 2 }}>
                    Nosotros
                  </Anchor>
                </Heading>
              </FooterWidget>
            </Col>
            <Col lg={2} md={4} sm={6}>
              <FooterWidget>
                <Heading as="h6" mt="-3px" mb="20px">
                  <Anchor path="/" color="text" $hover={{ layout: 2 }}>
                    Contacto
                  </Anchor>
                </Heading>
              </FooterWidget>
            </Col>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row alignItems="center">
            <Col md={6} textAlign={["center", null, "left"]}>
              {copyright && (
                <Text pb={["15px", 0]}>
                  &copy; {new Date().getFullYear()}{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: copyright,
                    }}
                  />
                </Text>
              )}
            </Col>
            <Col md={6} textAlign={["center", null, "right"]}>
              {socials && (
                <Social
                  space="16px"
                  tooltip={true}
                  shape="square"
                  variant="flat"
                >
                  {socials.map((social) => (
                    <SocialLink
                      key={social.id}
                      path={social.link}
                      title={social.title}
                    >
                      <i className={cn(social.icon, "link-icon")}></i>
                    </SocialLink>
                  ))}
                </Social>
              )}
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
  );
};

Footer.propTypes = {
  data: PropTypes.shape({
    socials: PropTypes.arrayOf(PropTypes.shape(SocialType)),
    copyright: PropTypes.string,
    contact: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
      address: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
};

export default Footer;
