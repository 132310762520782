import { breakpoints } from "./index";

export const theme = {
  colors: {
    primary: "#4f8fc0",
    text: "#ffffff",
    heading: "#53d2dc",
    border: "#26648e",
    borderTwo: "#26648e",
    borderDash: "#dddddd",
    white: "#ffffff",
    black: "#000000",
    secondary: "#ffe3b3",
    success: "#38cb89",
    info: "#17a2b8",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#f8f9fa",
    background: "#4f8fc0",
    gray: {
      100: "#dddfe0",
      200: "#F6FAFE",
      300: "d6d6d6",
      400: "#eeeeee",
      500: "#fafafa",
      600: "#f5f7fd",
      700: "#7e7e7e",
    },
    silver: "#8a979e",
    silverTwo: "#cccccc",
    kimberly: "#6d70a6",
    spring: "#F6F2ED",
    pattens: "#def0ff",
    yellow: "#ffb944",
    whiteSmoke: "#dddfe0",
  },
  lineHieghts: {
    body: 1.74,
    heading: 1.41,
  },
  fonts: {
    body: "Rubik",
    heading: "Rubik",
    fontAwesomePro: "Font Awesome 5 Pro",
    fontAwesomeBrand: "Font Awesome 5 Brands",
  },
  fontSizes: {
    body: "15px",
    h1: "56px",
    h2: "48px",
    h3: "40px",
    h4: "34px",
    h5: "24px",
    h6: "18px",
  },
  fontWeights: {
    body: 400,
    heading: 700,
  },
  radii: {
    sm: "3px",
    md: "5px",
    lg: "8px",
    round: "50%",
    pill: "500px",
  },
  shadows: {
    default: "0 0 40px rgba(51, 51, 51, 0.1)",
    sm: "0 0 20px rgba(51, 51, 51, 0.1)",
    md: "0 18px 40px rgba(51, 51, 51, 0.1)",
    lg: "0 0 40px 5px rgba(51, 51, 51, 0.1)",
    xl: "0 41px 43px rgba(51, 51, 51, 0.07)",
    input: "0 0 0 0.2rem rgb(1 104 250 / 25%)",
  },
  transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
  breakpoints: [...breakpoints],
};
